<template>
	<v-card flat v-if="initialized">
		<v-card-title>
			Linkler<v-spacer></v-spacer>
			<v-btn depressed class="text-capitalize" @click="add">
				<v-icon left x-small>fas fa-plus</v-icon>
				yeni
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-list dense>
				<v-list-item dense class="px-0 v-i" v-if="cmsQuickLinks.length === 0">
					<v-list-item-content class="justify-center text-center"> Henüz bir süreç eklenmemiş. </v-list-item-content>
				</v-list-item>
				<v-list-item
					dense
					class="px-0"
					v-for="(link, index) in $v.cmsQuickLinks.$each.$iter"
					:key="`businessStep-${index}`"
				>
					<v-list-item-content class="d-flex flex-row">
						<v-row align="center">
							<v-col cols="12" md="2" class="d-flex justify-center align-center">
								<v-img
									v-if="link.$model.cqiIcon"
									contain
									width="1000"
									height="60"
									:lazy-src="stepImage(link.$model)"
									:src="stepImage(link.$model)"
								></v-img>

								<v-img
									v-else-if="link.$model.iconBlob"
									contain
									width="100"
									height="60"
									:lazy-src="link.$model.iconBlob"
									:src="link.$model.iconBlob"
								></v-img>
								<div v-else>
									<v-btn
										class="text-capitalize"
										color="grey lighten-3"
										depressed
										light
										@click="chooseStepFile(index)"
										small
									>
										resim seç
									</v-btn>

									<input
										type="file"
										:ref="`stepFile-${index}`"
										class="d-none"
										accept=".png"
										@change="changeStepFile($event, index)"
									/>
								</div>
							</v-col>
							<v-col cols="12" md="5">
								<v-text-field
									label="Başlık"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									v-model="link.cqiTitle.$model"
									:error-messages="cqiTitleErrors(index)"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="5">
								<v-text-field
									label="Link"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									v-model="link.cqiLink.$model"
									:error-messages="cqiLinkErrors(index)"
								></v-text-field>
							</v-col>
						</v-row>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn outlined color="red darken-4" depressed class="text-capitalize" @click="remove(index)">
							<v-icon left x-small>fas fa-trash</v-icon>
							sil
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>
			<div class="d-flex justify-start py-5">
				<v-btn
					:loading="savePreloader"
					color="teal"
					class="text-capitalize"
					:dark="!$v.cmsQuickLinks.$invalid"
					@click="save"
					:disabled="$v.cmsQuickLinks.$invalid"
				>
					Değişiklikleri Kaydet
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import quickLinkValidationMixin from '@/mixins/validations/backoffice/quickLinkValidationMixin';
import { FETCH_SITE_SETTINGS, UPDATE_QUICK_LINK } from '@/store/modules/company.module';
import { DISPOSE_MESSAGE, SET_MESSAGE_DIALOG } from '@/store/modules/messageDialog.module';
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'QuickLinks',
	mixins: [validationMixin, quickLinkValidationMixin],
	data() {
		return {
			savePreloader: false,
			cmsQuickLinks: [],
		};
	},

	computed: {
		...mapGetters(['settings', 'company']),
		initialized() {
			return this.cmsQuickLinks !== null;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings();
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			updateQuickLink: UPDATE_QUICK_LINK,
		}),
		getFile(file) {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = (e) => {
					const array = new Uint8Array(e.target.result);
					const byte = [];
					for (let i = 0; i < array.length; i += 1) {
						byte.push(array[i]);
					}
					resolve({ name: file.name, file: byte });
				};
				reader.readAsArrayBuffer(file);
			});
		},
		async readAsDataURL(file) {
			const fileReader = new FileReader();
			return new Promise((resolve) => {
				if (file.type.indexOf('image') > -1) {
					this.showThumbnail = true;
					fileReader.onload = () => {
						resolve(fileReader.result);
					};
				}
				fileReader.readAsDataURL(file);
			});
		},
		checkFileExtension(files) {
			return new Promise((resolve, reject) => {
				const allowedExtensions = /(\.png)$/i;

				const hasIgnoredExt = Array.from(files).some((f) => !allowedExtensions.exec(f?.name));

				if (hasIgnoredExt) {
					reject();
					this.setMessageDialog({
						messageType: 'error',
						subTitle: 'Hatalı!',
						text: 'Geçersiz dosya türü!',
					});
				}
				resolve();
			});
		},
		chooseStepFile(index) {
			const input = this.$refs[`stepFile-${index}`][0];
			if (input) {
				input.click();
			}
		},
		async changeStepFile(e, index) {
			const data = e.target.files[0];

			this.checkFileExtension([data]).then(async () => {
				if (data) {
					const response = await this.getFile(data);

					const step = this.cmsQuickLinks[index];

					if (step) {
						step.iconByte = response.file;
						step.fileName = data.name;
						step.iconBlob = await this.readAsDataURL(data);
					}
				}
			});
		},
		stepImage(step) {
			return `${step.fileName}`;
		},

		add() {
			this.cmsQuickLinks.push({
				fileName: null,
				iconBlob: null,
				iconByte: null,
				cqiCmpId: this.company.cmpId,
				cqiOrder: this.company.banners.length + 1,
				cqiTitle: null,
				cqiLink: null,
			});
		},
		remove(index) {
			this.cmsQuickLinks.splice(index, 1);
		},

		save() {
			this.savePreloader = true;
			this.updateQuickLink(this.cmsQuickLinks)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı!',
						text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
					});
				})
				.finally(() => {
					this.savePreloader = false;
				});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.cmsQuickLinks = company.quickLinks;
			},
			deep: true,
		},
	},
};
</script>

<style></style>
