/* eslint-disable no-unused-expressions */
import { required } from 'vuelidate/lib/validators';

const quickLinkValidationMixin = {
	validations: {
		cmsQuickLinks: {
			$each: {
				cqiTitle: {
					required,
				},
				cqiLink: {
					required,
				},
			},
		},
	},
	methods: {
		// validation methods for this page
		cqiTitleErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsQuickLinks.$each[index].cqiTitle;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen bir başlık giriniz.');
			return errors;
		},
		cqiLinkErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsQuickLinks.$each[index].cqiLink;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen bir link giriniz.');
			return errors;
		},
	},
};

export default quickLinkValidationMixin;
